import Vue from 'vue'
import vSelect from 'vue-select'
import axios from "axios";
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import DualListBox from "dual-listbox-vue";

export default {
    components: {
        vSelect,
        axios,
        Datepicker,
        DualListBox
    },
    data: function() {
        return {
            pageTitle: "Grand livre",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            filtremodaltitle: "",
            listeCompte: [],
            plan_comptable_id: "",
            filtretexte : "",
            montant_total_debit: 0,
            montant_total_credit: 0,

            filtreDate: {
                dateDebut:"",
                dateFin:""
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'DATE': 'date_formatted',
                'N° FI': 'num_ecriture',
                'LIBELLE': 'libelle',
                'DEBIT': 'montant_debit',
                'CREDIT': 'montant_credit',
                'SOLDE': 'montant_solde',
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            disabledDates: {
                to: new Date(Date.now())
            },
            DualListSource: [],
            DualListDestination: [],
            date_exercice: {
                date_debut: "",
                date_fin: ""
            },
            afficherToutExercice: "",
            inclurebrouillard: "true",
            repporanouveau: "true",
            grandLivreData: [],
            printPDFValue:"",

        }
    },
    methods: {
        onChangeList: function ({ source, destination }) {
            this.DualListSource = source;
            this.DualListDestination = destination;
            this.filtretexte = JSON.stringify(destination);
        },
        onExport() {

            

            this.tabledata = [];
            let i_count = 0;
            console.log(this.grandLivreData);
            for(i_count=0; i_count<this.grandLivreData.length; i_count++) {
                

                var i_detail = 0;
                var GLDetail = this.grandLivreData[i_count].children;

                if(GLDetail != "") {

                    this.tabledata.push (
                        {
                            date_formatted: "",
                            num_ecriture: "",
                            libelle: this.grandLivreData[i_count]['libelle_compte'],
                            montant_debit: "",
                            montant_credit: ""
                        }
                    );

                    for(i_detail=0; i_detail<GLDetail.length; i_detail++) {

                        this.tabledata.push (
                            {
                                date_formatted: GLDetail[i_detail]['date_formatted'],
                                num_ecriture:  GLDetail[i_detail]['num_ecriture'],
                                libelle:  GLDetail[i_detail]['libelle'],
                                montant_debit:  GLDetail[i_detail]['montant_debit'],
                                montant_credit:  GLDetail[i_detail]['montant_credit'],
                                montant_solde: GLDetail[i_detail]['solde_ligne']
                            }
                        );

                    }

                    this.tabledata.push (
                        {
                            date_formatted: "",
                            num_ecriture: "",
                            libelle: "MOUVEMENTS",
                            montant_debit: this.grandLivreData[i_count].totaux.t_debit,
                            montant_credit: this.grandLivreData[i_count].totaux.t_credit,
                            montant_solde: this.grandLivreData[i_count].totaux.solde,
                        }
                    );

                    this.tabledata.push (
                        {
                            date_formatted: "",
                            num_ecriture: "",
                            libelle: this.grandLivreData[i_count].solde.text_solde,
                            montant_debit: this.grandLivreData[i_count].solde.t_debit,
                            montant_credit: this.grandLivreData[i_count].solde.t_credit,
                        }
                    );
                    this.tabledata.push (
                        {
                            date_formatted: "",
                            num_ecriture: "",
                            libelle: "",
                            montant_debit: "",
                            montant_credit: ""
                        }
                    );

                }

            }
            console.log(this.tabledata);
            this.json_data = this.tabledata;
            $("#excel-download").trigger("click");
            
        },
        openModal(){
            this.$bvModal.show("crudmodal");
        },
        closeModal(){
            this.$bvModal.hide("crudmodal");
            this.setFilter();
        },
        openFiltreModal(){
            this.filtremodaltitle = "Filtre avancé - Choisir les comptes à afficher";
            this.$bvModal.show("filtremodal");
        },
        getAllCompte() {
            var that = this;
            axios.get(that.base_url+"/plancomptable/getall").then(response => {
                // console.log("===> Source ----");
                that.DualListSource = response.data;
                // that.DualListDestination = response.data;
                // console.log(that.DualListSource);
                // console.log("===> Source ----");
            });

        },
        getDateExercice() {
            var that = this;
            axios.get(that.base_url+"/exercice/get/1").then(response => {
                console.log(response.data);
                that.date_exercice.date_debut = moment(response.data[0].date_debut).format('DD/MM/YYYY');
                that.date_exercice.date_fin = moment(response.data[0].date_fin).format('DD/MM/YYYY');
            });

        },
        // notOverRange(date) {
        //     return date < new Date(this.date_exercice.date_debut) || date > new Date(this.date_exercice.date_fin);
        // },
        changeExercice() {
            if(this.$refs.afficherToutExercice.checked == true) {
                this.filtreDate.dateDebut = this.date_exercice.date_debut;
                this.filtreDate.dateFin = this.date_exercice.date_fin;
            } else {
                this.filtreDate.dateDebut = moment().format('DD/MM/YYYY');
                this.filtreDate.dateFin = moment().format('DD/MM/YYYY');
            }
        },
        closeFiltreModal(){
            this.$bvModal.hide("filtremodal");
        },
        onRowClass(dataItem, index) {
            if(index =="") console.log("");
            return dataItem.brouillard_groupe_id % 2 == 0
              ? 'bg-gray'
              : 'bg-white'
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        onLoading() {
            console.log('loading... show your spinner here');
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        initListCompte(filtre) {
            //filtre = this.crudform.parent_id;
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                //console.log(this.listeCompte);
            });
        },
        inclurebrouillardFunc() {
            if(this.$refs.inclurebrouillards.checked == true) {
                this.inclurebrouillard = "true";
            } else {
                this.inclurebrouillard = "";
            }
        },
        repporanouveauFunc() {
            if(this.$refs.repporanouveaux.checked == true) {
                this.repporanouveau = "true";
            } else {
                this.repporanouveau = "";
            }
        },
        onSubmitSearch() {
            this.closeFiltreModal();
            var that = this;
            axios.post(this.base_url+"/grandlivre/fetchall", $("#onSubmitSearch").serialize()).then(function(response) {
                
                that.grandLivreData = response.data;
                let i_count = 0;
                for(i_count = 0; i_count < that.grandLivreData.length; i_count++) {
                    var childrens = that.grandLivreData[i_count]['children'];
                    var total_debit = 0;
                    var total_credit = 0;
                    var solde_par_ligne = 0;
                    let j_count = 0;
                    for(j_count = 0; j_count < childrens.length; j_count++) {
                        childrens[j_count]['solde_ligne'] = Number(solde_par_ligne) + Number(childrens[j_count]['montant_debit']) - Number(childrens[j_count]['montant_credit']);
                        solde_par_ligne = childrens[j_count]['solde_ligne'];
                        total_debit += Number(childrens[j_count]['montant_debit']);
                        total_credit += Number(childrens[j_count]['montant_credit']);

                        // if(Number(childrens[j_count]['montant_debit']) == 0 && Number(childrens[j_count]['montant_credit']) == 0) {
                        //     childrens.splice(j_count, 1);
                        // }
                        
                    }
                    that.grandLivreData[i_count]['totaux'] = {t_debit: total_debit, t_credit: total_credit, solde: solde_par_ligne};

                    var text_solde = Number(total_debit) > Number(total_credit) ? "SOLDE DEBITEUR" : "SOLDE CREDITEUR";
                    text_solde = Number(total_debit) == Number(total_credit) ? "SOLDE" : text_solde;

                    that.grandLivreData[i_count]['solde'] = {
                        t_debit: (total_debit > total_credit ? total_debit - total_credit : 0), 
                        t_credit: (total_debit < total_credit ? total_credit - total_debit : 0), 
                        text_solde: text_solde
                    };
                }
                console.log(that.grandLivreData);

            });
        },
        onPrintGl1() {
            this.closeFiltreModal();
            var that = this;

            axios.post(this.base_url+"/grandlivre/printgl1", $("#onSubmitSearch").serialize()).then(function(response) {
                
                that.printPDFValue = response.data;
                console.log(that.base_url+"/"+response.data);
                window.open(that.base_url+"/"+response.data);

            });
        }
    },
    computed: {
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        
        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.getAllCompte();
        this.getDateExercice();
        
        //this.initListCompte('');
    },
    mounted: function() {
        // var that = this;
        this.initListCompte('');
        this.openFiltreModal();

    }
}